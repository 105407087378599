import { Admin, Role } from "src/types/organization/admins";
import { Badge } from "src/types/organization/badges";
import { Carrier, Group } from "src/types/organization/carriers";
import { Operation } from "src/types/organization/operations";
import { Profile } from "src/types/organization/profiles";
import { Site } from "src/types/organization/sites";
import { PaginatedResponse } from "src/types/responses/PaginatedResponse";
import api from "../clients/api";
import { Me } from "../../types/organization/me";
import { FriendlyProfile, GroupFriendlyProfile } from "src/types/organization/groups";
import { Categories } from "src/types/organization/categories";

class OrganizationApi {
  async getSites(): Promise<Site[]> {
    const { data } = await api.get<Site[]>(`/sites`);

    return data;
  }

  async getCarriers(
    offset: number,
    limit: number,
    siteToken: string,
    email: string,
    search: string,
    friendlyProfileIds: string[],
    groupId?: number
  ): Promise<PaginatedResponse<Carrier>> {
    const { data } = await api.get<PaginatedResponse<Carrier>>(`/carriers`, {
      params: { offset, limit, siteToken, email, search, friendlyProfileIds, groupId },
    });

    return data;
  }

  async getCarrier(id: number, searchProfiles?: string): Promise<Carrier> {
    const { data } = await api.get<Carrier>(`/carriers/${id}`, {
      params: {
        search: searchProfiles,
      },
    });

    return data;
  }

  async getSitesProfiles(siteToken: string, search?: string, groupId?: number): Promise<Profile[]> {
    const { data } = await api.get<Profile[]>(`/sites/${siteToken}/profiles`, {
      params: {
        search,
        groupId,
      },
    });

    return data;
  }
  async fetchSitesFriendlyProfiles(
    siteToken: string,
    search?: string,
    groupId?: number
  ): Promise<GroupFriendlyProfile> {
    const { data } = await api.get<GroupFriendlyProfile>(`/sites/${siteToken}/profiles/friendly`, {
      params: {
        search,
        groupId,
      },
    });

    return data;
  }

  async updateSitesDefaultProfiles(siteToken: string, profileIds: number[]): Promise<Profile[]> {
    const { data } = await api.put<Profile[]>(`/sites/${siteToken}/default-profiles`, {
      profileIds,
    });

    return data;
  }

  async getSitesBadges(
    siteToken: string,
    limit: number,
    offset: number,
    onlyFree?: boolean,
    token?: string
  ): Promise<PaginatedResponse<Badge>> {
    const { data } = await api.get<PaginatedResponse<Badge>>(`/sites/${siteToken}/badges`, {
      params: { limit, offset, token, onlyFree },
    });

    return data;
  }

  async getCarrierOperations(
    limit: number,
    offset: number,
    search: string,
    language: string
  ): Promise<PaginatedResponse<Operation>> {
    const { data } = await api.get<PaginatedResponse<Operation>>(`/carrier-operations`, {
      params: { limit, offset, search },
      headers: { language },
    });

    return data;
  }

  async deleteCarrierOperation(jobId: number): Promise<Operation> {
    const { data } = await api.delete<Operation>(`/carrier-operations/${jobId}`);

    return data;
  }

  async assignCarrierProfiles(
    siteToken: string,
    carrierId: string,
    organizationProfilesId: string[] | number[],
    from: string,
    to: string
  ): Promise<Operation> {
    const { data } = await api.patch<any>(`/sites/${siteToken}/carrier-profiles/${carrierId}`, {
      organizationProfilesId,
      from,
      to,
    });

    return data;
  }

  async detachCarrierProfiles(
    siteToken: string,
    carrierId: string,
    carrierProfilesId: string[] | number[]
  ): Promise<Operation> {
    const { data } = await api.delete<any>(`/sites/${siteToken}/carrier-profiles/${carrierId}`, {
      params: { carrierProfilesId },
    });

    return data;
  }

  async batchAssignCarrierProfiles(
    siteToken: string,
    organizationProfilesId: string[] | number[],
    carriersId: string[] | number[],
    from: string,
    to: string
  ): Promise<Operation> {
    const { data } = await api.post<any>(`/sites/${siteToken}/batch-carrier-profiles/assign`, {
      organizationProfilesId,
      carriersId,
      from,
      to,
    });

    return data;
  }

  async batchDetachCarrierProfiles(
    siteToken: string,
    profilesId: string[] | number[],
    carriersId: string[] | number[]
  ): Promise<Operation> {
    const { data } = await api.post<any>(`/sites/${siteToken}/batch-carrier-profiles/detach`, {
      profilesId,
      carriersId,
    });

    return data;
  }

  async assignBadgeToCarrier(carrierId: number, badgeId: number): Promise<Operation> {
    const { data } = await api.post<any>(`/carrier-badges/${carrierId}`, {
      badgeId,
    });

    return data;
  }

  async detachBadgeFromCarrier(carrierId: number, badgeId: number): Promise<Operation> {
    const { data } = await api.delete<any>(`/carrier-badges/${carrierId}/${badgeId}`);

    return data;
  }

  async deleteCarrier(carrierId: number): Promise<Carrier> {
    const { data } = await api.delete<Carrier>(`/carriers/${carrierId}`);

    return data;
  }

  async getAdmins(): Promise<Admin[]> {
    const { data } = await api.get<Admin[]>(`/admins`);

    return data;
  }

  async addAdmin(friendlyName: string, email: string): Promise<Admin> {
    const { data } = await api.post<Admin>(`/admins`, { friendlyName, email });

    return data;
  }

  async removeAdmin(organizationAdminId: number): Promise<void> {
    const { data } = await api.delete<void>(`/admins/${organizationAdminId}`);

    return data;
  }

  async getMe(): Promise<Me> {
    const { data } = await api.get<Me>(`/profile`);

    return data;
  }

  async inviteAdmin(friendlyName: string, email: string, role: Role): Promise<Admin> {
    const { data } = await api.post<Admin>(`/admins/invite`, { friendlyName, email, role });

    return data;
  }
  async fetchGroups(carrierId: string, search: string): Promise<Group[]> {
    const { data } = await api.get<Group[]>(`/carriers/${carrierId}/user-groups`, {
      params: {
        search,
      },
    });
    return data;
  }

  async fetchUserCategories(siteToken: string, carrierId: string): Promise<Categories> {
    const { data } = await api.get<Categories>(`/carriers/${carrierId}/categories`, {
      params: {
        siteToken,
      },
    });
    return data;
  }

  async detachProfilesFromCategory(
    siteToken: string,
    carrierId: string,
    profilesIds: number[]
  ): Promise<FriendlyProfile> {
    const { data } = await api.delete<FriendlyProfile>(
      `/sites/${siteToken}/carrier-profiles/${carrierId}/friendly`,
      {
        params: {
          friendlyProfilesId: profilesIds,
        },
      }
    );
    return data;
  }

  async attachProfilesToCategory(
    siteToken: string,
    carrierId: string,
    profilesIds: number[]
  ): Promise<FriendlyProfile> {
    const { data } = await api.patch<FriendlyProfile>(
      `/sites/${siteToken}/carrier-profiles/${carrierId}/friendly`,
      {
        friendlyProfileIds: profilesIds,
      }
    );
    return data;
  }
}

export const organizationApi = new OrganizationApi();
