import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Role } from 'src/types/organization/admins';

import { AppAbility, rolePermissions } from './app-ability';

export function defineAbilityFor(role: Role): AppAbility {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility);

  if (typeof rolePermissions[role] === 'function') {
    rolePermissions[role](role, builder);
  } else {
    throw new Error(`Trying to use unknown role "${role}"`);
  }

  return builder.build();
}
