import { createContextualCan } from '@casl/react';
import { useAbility } from '@casl/react';
import { ReactNode, createContext } from 'react';
import { AppAbility } from 'src/permissions/app-ability';
import { defineAbilityFor } from 'src/permissions/define-ability';
import { Role } from 'src/types/organization/admins';

export const AbilityContext = createContext<AppAbility>(defineAbilityFor(Role.SUPERVISOR));

export const usePermissions = () => {
  const ability = useAbility(AbilityContext);

  if (!ability) {
    throw new Error('usePermissions must be used within a AbilityProvider.');
  }

  return ability;
};

export const AbilityContextProvider = ({ children, role }: { children: ReactNode; role: Role }) => {
  const ability = defineAbilityFor(role);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const Can = createContextualCan(AbilityContext.Consumer);
