import type { FC } from "react";

import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core";
import type { SxProps } from "@material-ui/system";
import { usePopover } from "../../hooks/use-popover";
import { Selector as SelectorIcon } from "../../icons/selector";

interface Option {
  value: string;
  label: string;
}

interface NavbarPopoverProps {
  selected: Option;
  onChange: (value: Option) => void;
  options: Option[];
  sx?: SxProps;
}

export const NavbarPopover: FC<NavbarPopoverProps> = (props) => {
  const { selected, options, onChange, sx, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleChange = (value: Option): void => {
    handleClose();
    onChange?.(value);
  };

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: "flex",
          p: 1,
          width: 180,
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.1)",
          },
          ...sx,
        }}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            color: "primary.contrastText",
            mr: 3,
          }}
          variant="subtitle2"
        >
          {selected.label}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 200 },
        }}
      >
        <List>
          {options.map((option) => (
            <ListItem
              key={option.value}
              button
              selected={option.value === selected.value}
              onClick={() => handleChange(option)}
            >
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};
