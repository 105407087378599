import type { FC } from "react";
import { Box, Container, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const Footer: FC = () => {
  const { t } = useTranslation();
  const links = [
    {
      label: t("common.aboutUs"),
      href: "https://www.signalos.io/",
    },
  ];

  return (
    <div>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          py: 3,
          "& a": {
            mt: {
              sm: 0,
              xs: 1,
            },
            "&:not(:last-child)": {
              mr: {
                sm: 5,
                xs: 0,
              },
            },
          },
        }}
      >
        <Typography color="textSecondary" variant="caption">
          © 2022 SignalOS
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {links.map((link) => (
          <Link
            color="textSecondary"
            href={link.href}
            key={link.label}
            target="_blank"
            underline="none"
            variant="body2"
          >
            {link.label}
          </Link>
        ))}
      </Container>
    </div>
  );
};
