import { AbilityBuilder, MongoAbility } from "@casl/ability";
import { ForcedSubject } from "@casl/ability";
import { Role } from "src/types/organization/admins";

const actions = ["create", "update", "read", "delete"] as const;
const subjects = [
  "User",
  "all",
  "Admin",
  "GroupUsers",
  "UserBadges",
  "UserFriendlyAccessLevels",
  "GroupFriendlyAccessLevels",
  "GdprClause",
  Role.SUPERVISOR,
  Role.ADMIN,
  Role.OWNER,
  "Group",
] as const;

type AppAbilities = [
  (typeof actions)[number],
  (typeof subjects)[number] | ForcedSubject<Exclude<(typeof subjects)[number], "all">>
];

export type AppAbility = MongoAbility<AppAbilities>;

type DefinePermissions = (role: Role, builder: AbilityBuilder<AppAbility>) => void;
type Roles = "admin" | "owner" | "supervisor";

export const rolePermissions: Record<Roles, DefinePermissions> = {
  owner(role, { can }) {
    can([...actions], "all");
  },
  admin(role, { cannot, can }) {
    can([...actions], "all");
    cannot("create", [Role.ADMIN, Role.OWNER]);
    cannot("delete", "Admin");
  },
  supervisor(role, { can, cannot }) {
    can(["update", "create", "delete"], "GroupUsers");
    can(["update", "delete", "create"], "UserBadges");
    can(["update", "delete", "create"], "UserFriendlyAccessLevels");
    can(["create", "update", "delete"], "User");
    cannot(["create"], [Role.OWNER, Role.ADMIN, Role.SUPERVISOR]);
  },
};
