import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import { GuestGuard } from "./components/Guards/GuestGuard";
import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//Employees pages
const Carriers = Loadable(
  lazy(() => import("./containers/carriers").then((module) => ({ default: module.default })))
);

const Carrier = Loadable(
  lazy(() =>
    import("./containers/carriers/[carrierId]").then((module) => ({
      default: module.default,
    }))
  )
);

const Admins = Loadable(
  lazy(() => import("./containers/admins").then((module) => ({ default: module.AdminsContainer })))
);

const BadgesPage = Loadable(
  lazy(() =>
    import("./containers/badges").then((module) => ({
      default: module.default,
    }))
  )
);

const OperationsPage = Loadable(
  lazy(() =>
    import("./containers/operations").then((module) => ({
      default: module.default,
    }))
  )
);

const SitesPage = Loadable(
  lazy(() =>
    import("./containers/sites").then((module) => ({
      default: module.SitesContainer,
    }))
  )
);

const Site = Loadable(
  lazy(() =>
    import("./containers/sites/[siteToken]").then((module) => ({
      default: module.SiteContainer,
    }))
  )
);

// const AccessRightsPages = Loadable(
//   lazy(() =>
//     import("./containers/accessRights").then((module) => ({
//       default: module.default,
//     }))
//   )
// );

const EntrancesPage = Loadable(
  lazy(() =>
    import("./containers/entrances").then((module) => ({
      default: module.default,
    }))
  )
);

const ElevatorsPage = Loadable(
  lazy(() =>
    import("./containers/elevators").then((module) => ({
      default: module.default,
    }))
  )
);

const FloorsPage = Loadable(
  lazy(() =>
    import("./containers/floors").then((module) => ({
      default: module.default,
    }))
  )
);

const Groups = Loadable(
  lazy(() =>
    import("./containers/groups").then((module) => ({
      default: module.GroupsContainer,
    }))
  )
);
const Group = Loadable(
  lazy(() =>
    import("./containers/groups/[groupId]").then((module) => ({
      default: module.GroupContainer,
    }))
  )
);

const GroupFriendlyProfiles = Loadable(
  lazy(() =>
    import("./containers/groups/groupFriendlyProfiles").then((module) => ({
      default: module.GroupFriendlyProfilesContainer,
    }))
  )
);
const GroupCarriers = Loadable(
  lazy(() =>
    import("./containers/groups/groupCarriers").then((module) => ({
      default: module.GroupCarriersContainer,
    }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

// Auth pages
const Login = Loadable(
  lazy(() => import("./containers/login").then((module) => ({ default: module.Login })))
);
const PasswordRecovery = Loadable(
  lazy(() =>
    import("./containers/PasswordRecovery").then((module) => ({
      default: module.PasswordRecovery,
    }))
  )
);
const PasswordReset = Loadable(
  lazy(() =>
    import("./containers/PasswordReset").then((module) => ({ default: module.PasswordReset }))
  )
);
const Register = Loadable(
  lazy(() => import("./containers/register").then((module) => ({ default: module.Register })))
);
const VerifyCode = Loadable(
  lazy(() => import("./containers/VerifyCode").then((module) => ({ default: module.VerifyCode })))
);

const routes: PartialRouteObject[] = [
  {
    path: "carriers",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Carriers />,
      },

      {
        path: ":carrierId",
        element: <Carrier />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/carriers" replace />,
  },
  {
    path: "badges",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <BadgesPage />,
      },
    ],
  },
  {
    path: "operations",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <OperationsPage />,
      },
    ],
  },
  {
    path: "admins",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Admins />,
      },
    ],
  },
  {
    path: "sites",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <SitesPage />,
      },
      {
        path: ":siteToken",
        element: <Site />,
      },
    ],
  },
  {
    path: "entrances",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <EntrancesPage />,
      },
    ],
  },
  // {
  //   path: "access-rights",
  //   element: (
  //     <AuthGuard>
  //       <Layout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/",
  //       element: <AccessRightsPages />,
  //     },
  //   ],
  // },
  {
    path: "elevators",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <ElevatorsPage />,
      },
    ],
  },
  {
    path: "floors",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <FloorsPage />,
      },
    ],
  },
  {
    path: "groups",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Groups />,
      },
      {
        path: ":groupId",
        element: <Group />,
        children: [
          {
            path: "carriers",
            element: <GroupCarriers />,
          },
          // {
          //   path: "profiles",
          //   element: <GroupProfiles />,
          // },
          {
            path: "friendly-profiles",
            element: <GroupFriendlyProfiles />,
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "verify-code",
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    ),
  },
  {
    path: "password-recovery",
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    ),
  },
  {
    path: "password-reset",
    element: <PasswordReset />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
