import type { FC, ReactNode } from "react";

interface GuestGuardProps {
  children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  // const { isAuthenticated } = useAuth();

  // if (isAuthenticated) {
  //   return <Navigate to="/dashboard" />;
  // }

  return <>{children}</>;
};
