import { useSettings } from "../../contexts/settings-context";

export const Logo = (props) => {
  const { settings } = useSettings();
  return (
    <svg
      width={props.width ? props.width : "144"}
      height={props.height ? props.height : "32"}
      viewBox="0 0 144 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4299 6.81079H11.9782C9.17023 6.81079 6.82437 8.86484 6.3623 11.5675H23.8141C26.622 11.5675 28.9679 9.51349 29.4299 6.81079Z"
        fill="white"
      />
      <path
        d="M23.8141 13.5857H6.3623C6.82437 16.3244 9.17023 18.3785 11.9782 18.3785H29.4299C28.9679 15.6758 26.622 13.5857 23.8141 13.5857Z"
        fill="white"
      />
      <path
        d="M6.36226 11.5676H5.72248C2.55912 11.5676 0 8.97297 0 5.8018C0 2.59459 2.55912 0 5.72248 0H23.814C26.9419 0 29.501 2.59459 29.501 5.8018C29.501 6.16216 29.4654 6.48649 29.3943 6.81081H11.9781C9.17018 6.81081 6.82432 8.86486 6.36226 11.5676ZM6.36226 13.5856H5.72248C2.55912 13.5856 0 16.1802 0 19.3874C0 22.5946 2.55912 25.1892 5.72248 25.1892H6.36226C6.82432 22.4865 9.17018 20.4324 11.9781 20.4324H29.4299C29.501 20.1081 29.5365 19.7477 29.5365 19.4234C29.5365 19.0631 29.501 18.7387 29.4299 18.4144H11.9781C9.17018 18.3784 6.82432 16.3243 6.36226 13.5856ZM35.7566 12.5766C35.7566 9.36937 33.1975 6.77477 30.0341 6.77477H29.3943C28.9323 9.47748 26.5864 11.5315 23.7785 11.5315H6.36226C6.29117 11.8559 6.25563 12.2162 6.25563 12.5405C6.25563 12.9009 6.29117 13.2252 6.36226 13.5495H23.814C26.622 13.5495 28.9678 15.6036 29.4299 18.3063H30.0697C33.233 18.3784 35.7566 15.7838 35.7566 12.5766ZM23.814 25.1892H6.36226C6.29117 25.5135 6.25563 25.8739 6.25563 26.1982C6.25563 29.4054 8.81475 32 11.9781 32H30.0697C33.233 32 35.7921 29.4054 35.7921 26.1982C35.7921 22.991 33.233 20.3964 30.0697 20.3964H29.4299C28.9678 23.0991 26.622 25.1892 23.814 25.1892Z"
        fill="url(#paint0_linear_902_846)"
      />
      <path
        d="M6.3623 25.1891H23.8141C26.622 25.1891 28.9679 23.1351 29.4299 20.4324H11.9782C9.17023 20.3963 6.82437 22.4504 6.3623 25.1891Z"
        fill="white"
      />
      <path
        d="M47.3799 19.0633H50.6854C50.721 20.2886 51.8583 21.2615 53.3867 21.2615C54.8795 21.2615 55.8392 20.4687 55.8392 19.3156C55.8392 18.4147 55.1639 17.766 53.7777 17.2976L51.7873 16.721C48.1974 15.748 47.5576 13.5498 47.5576 12.0363C47.5576 9.04531 50.0812 7.09937 53.2445 7.09937C56.3368 7.09937 58.7182 9.00928 58.7182 12.1804H55.4127C55.4127 10.9913 54.5952 10.0904 53.1735 10.0904C51.8939 10.0904 50.8987 10.8831 50.8987 12.0003C50.8987 12.3967 51.0053 13.2976 52.7469 13.766L54.5952 14.3066C57.0832 15.0273 59.2514 16.3246 59.2514 19.1714C59.2514 22.4868 56.479 24.2165 53.3512 24.2165C49.7968 24.1805 47.3799 22.0183 47.3799 19.0633Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M60.0684 8.79209C60.0684 7.74704 60.9569 6.91821 62.0588 6.91821C63.1606 6.91821 64.0492 7.74704 64.0492 8.79209C64.0492 9.83713 63.1606 10.702 62.0588 10.702C60.9569 10.702 60.0684 9.83713 60.0684 8.79209ZM60.4949 12.684H63.6227V23.9633H60.4949V12.684Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M77.0576 12.6845V22.4143C77.0576 27.3512 74.6051 29.6575 70.6598 29.6575C68.2784 29.6575 66.2169 28.2881 65.2572 26.2341L67.8519 25.0809C68.3139 26.0899 69.4869 26.8107 70.6598 26.8107C72.6502 26.8107 73.9298 25.6935 73.9298 22.7386V22.5584C73.1123 23.6395 71.5839 24.144 70.3399 24.144C67.4609 24.144 64.8662 21.9098 64.8662 18.2701C64.8662 14.6305 67.4609 12.3962 70.3399 12.3962C71.6195 12.3962 73.1123 12.9007 73.9298 13.9818V12.6485L77.0576 12.6845ZM73.8231 18.3061C73.8231 16.5764 72.4014 15.3872 70.9441 15.3872C69.3802 15.3872 68.0651 16.6125 68.0651 18.3061C68.0651 19.9638 69.3802 21.2251 70.9441 21.2251C72.4725 21.2251 73.8231 20.0359 73.8231 18.3061Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M89.8899 17.7656V23.9638H86.7621V18.126C86.7621 16.3242 85.7313 15.5314 84.6295 15.5314C83.4921 15.5314 82.1415 16.144 82.1415 18.198V23.9638H79.0137V12.6845H82.1415V14.5584C82.6035 13.0449 84.3807 12.3962 85.4825 12.3962C88.3971 12.3962 89.9254 14.3782 89.8899 17.7656Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M103.218 12.6846V23.9639H100.09V22.5585C99.308 23.6756 97.7441 24.1801 96.5 24.1801C93.621 24.1801 91.0264 21.9459 91.0264 18.3062C91.0264 14.6666 93.621 12.4324 96.5 12.4324C97.7796 12.4324 99.2724 12.9008 100.09 14.054V12.6846H103.218ZM99.9833 18.3062C99.9833 16.5765 98.5616 15.3873 97.1043 15.3873C95.5404 15.3873 94.2253 16.6126 94.2253 18.3062C94.2253 19.9639 95.5404 21.2252 97.1043 21.2252C98.6327 21.2252 99.9833 20.036 99.9833 18.3062Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M105.174 6.66675H108.302V23.964H105.174V6.66675Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M114.237 14.3427C114.77 10.6671 117.578 7.7121 121.203 7.17156C126.57 6.34273 131.084 10.6671 130.871 16.0364C130.658 20.613 126.855 24.2526 122.341 24.1806C117.329 24.0725 113.49 19.604 114.237 14.3427ZM127.53 15.64C127.53 12.613 125.397 10.1986 122.376 10.2346C119.852 10.2707 117.791 12.3247 117.507 14.8833C117.116 18.2346 119.284 21.0454 122.483 21.0454C125.433 21.0454 127.53 18.631 127.53 15.64Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <path
        d="M131.759 19.0633H135.064C135.1 20.2886 136.237 21.2615 137.766 21.2615C139.258 21.2615 140.218 20.4687 140.218 19.3156C140.218 18.4147 139.543 17.766 138.157 17.2976L136.166 16.721C132.576 15.748 131.937 13.5498 131.937 12.0363C131.937 9.04531 134.46 7.09937 137.623 7.09937C140.716 7.09937 143.097 9.00928 143.097 12.1804H139.792C139.792 10.9913 138.974 10.0904 137.552 10.0904C136.273 10.0904 135.278 10.8831 135.278 12.0003C135.278 12.3967 135.384 13.2976 137.126 13.766L138.974 14.3066C141.462 15.0273 143.63 16.3246 143.63 19.1714C143.63 22.4868 140.858 24.2165 137.73 24.2165C134.176 24.1805 131.759 22.0183 131.759 19.0633Z"
        fill={settings.theme === "dark" ? "white" : "black"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_902_846"
          x1="-0.548114"
          y1="5.19643"
          x2="36.5732"
          y2="26.3354"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00113545" stopColor="#579EFF" />
          <stop offset="1" stopColor="#5764FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
