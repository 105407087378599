import React, { useRef, useState } from "react";

import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";

import type { SxProps } from "@material-ui/system";
import { AdminPanelSettings, ChangeCircle, Settings } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SettingsPopoverProps {
  sx?: SxProps;
}

export const SettingsPopover: React.FC<SettingsPopoverProps> = (props) => {
  const { ...other } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const settings = [
    {
      title: t("admins.title"),
      href: "/admins",
      icon: <AdminPanelSettings />,
    },
    {
      title: t("operations.title"),
      href: "/operations",
      icon: <ChangeCircle />,
    },
  ];

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
        sx={{
          mx: 1,
          display: {
            md: "inline-flex",
            xs: "none",
          },
        }}
      >
        <Settings />
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {settings.map((option) => (
          <MenuItem
            onClick={() => {
              navigate(option.href);
              setOpen(false);
            }}
            key={option.href}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  height: 20,
                  width: 20,
                }}
              >
                {option.icon}
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {option.title}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
