import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        search: "Search",
        all: "All",
        bulkActions: "Bulk Actions",
        noResultsFound: "No results found",
        page: "Page",
        of: "of",
        success: "Success",
        error: "Error",
        from: "From",
        To: "To",
        to: "to",
        noData: "No data",
        alreadyInUse: "Already in use",
        assign: "Assign",
        yes: "Yes",
        no: "No",
        areYouSure: "Are You sure",
        logout: "Logout",
        aboutUs: "About us",
        show: "Show",
        default: "Default",
        notDefault: "Not default",
        perPage: "Per page:",
        cancel: "Cancel",
        confirm: "Confirm",
        delete: "Delete",
        availableSoon: "Available soon",
        noObjectsHere: "There are not objects here yet.",
        inherited: "Profile is inherited from group",
        privacyPolicy: "Privacy policy",
        termsAndConditions: "Terms and conditions",
        required: "Field is required",
        button: {
          save: "Save",
          cancel: "Cancel",
          detach: "Detach",
        },
        profileVariants: {
          assigning: "Assigning",
          removing: "Removing",
          synchronizing: "Synchronizing",
          scheduled: "Scheduled",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Withdrawing connection error",
          removing_error: "Removing error",
          assigning_error: "Assigning error",
          failed: "Failed",
          max_limit_exhausted: "Max limit exhausted",
          assigned: "Assigned",
          queued_to_remove: "Queued to remove",
          queued_to_assign: "Queued to assign",
          default: "N/A",
        },
        badgesVariants: {
          assigned: "Assigned",
          available: "Available",
          blocked: "Blocked",
          error: "Error",
          other: "Other",
          max_limit_exhausted: "Max limit exhausted",
          new: "New",
          queued: "Queued",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Withdrawing connection error",
        },
        operationCodesVariants: {
          update_badge_access_control: "Update badge access control",
          synchronize_carrier_with_digital_twin: "Synchronize user with digital twin",
          create_carrier_access_control: "Create user access control",
          attach_badge_to_carrier: "Attach badge to user",
          detach_badge_from_carrier: "Detach badge from user",
          synchronize_attached_badge_with_digital_twin:
            "Synchronize attached badge with digital twin",
          synchronize_detached_badge_with_digital_twin:
            "Synchronize detached badge with digital twin",
          attach_carrier_profiles: "Attach user profiles",
          detach_carrier_profiles: "Detach user profiles",
          synchronize_attached_profiles_with_digital_twin:
            "Synchronize attached profiles with digital twin",
          synchronize_detached_profiles_with_digital_twin:
            "Synchronize detached profiles with digital twin",
          synchronize_new_site_profiles: "Synchronize new site profiles",
        },
        status: {
          failed: "Failed",
          completed: "Completed",
          cancelled: "Cancelled",
        },
      },
      carriers: {
        title: "Users",
        tab: "Users | SignalOS",
        table: {
          columns: {
            sites: "Buildings",
            createdAt: "Created at",
            updatedAt: "Updated at",
          },
          buttons: {
            assignProfiles: "Assign profiles",
            detachProfiles: "Detach profiles",
            youNeedTo: "You need to choose site first",
            delete: "Delete",
          },
        },
        modal: {
          assignProfiles: "Assign profiles to selected users",
          detachProfiles: "Detach profiles from selected users",
          deleteMessage: "Are you sure you want to delete carrier?",
        },
        assignSuccess: "Badge assigned successfully",
        deleteSuccess: "Carrier deleted successfully",
        removeCarrierTooltip: "Only owner or admin can remove a user",
      },
      carrier: {
        assignedSuccess: "Assigned successfully - it can take some time to progress",
        detachedSuccess: "Detached successfully - it can take some time to progress",
        accessControls: "Access controls",
        assignBadge: "Assign badge",
        assignProfilesToCarrier: "Assign profiles to user",
        detachProfilesFromCarrier: "Detach profiles from user",
        categoriesSuccess: "Profiles updated successfully",
        categoriesAttachSuccess: "Profiles attach successfully",
        categoriesDetachSuccess: "Profiles detach successfully",
        accessControlsProfiles: {
          title: "Access control",
          columns: {
            accessControlId: "Access control id",
            state: "State",
            createdAt: "Created at",
            updatedAt: "Updated at",
          },
          completed: "Completed",
        },
        profiles: {
          title: "Profiles categories",
          profileName: "Access right name",
          assignProfiles: "Assign access rights",
          detachCheckedProfiles: "Detach checked access rights",
          thereAreNoProfilesToAssign: "There are no access rights to assign",
          searchDialogTitle: "Search users by access rights",
          profilesFilterTitle: "Profiles",
          columns: {
            profileId: "Id",
            name: "Name",
            state: "State",
            from: "From",
            to: "To",
            source: "Source",
            createdAt: "Created at",
            updatedAt: "Updated at",
          },
          sorry: "Sorry, but this user does not have an account in the selected building",
          admin: "Admin",
          group: "Group",
          GROUP: "Group",
          INDIVIDUAL: "Individual",
        },
        friendlyProfiles: {
          title: "Profiles",
        },
        badges: {
          title: "Badges",
          assignBadge: "Assign badge",
          thereAreNoBadges: "There are no badges to assign",
          errorTooltip: "This badge comes from external source and cant be detached",
          columns: {
            type: "Type",
            accessControls: "Access controls",
            createdAt: "Created at",
            updatedAt: "Updated at",
          },
        },
        groups: {
          title: "Groups",
          columns: {
            id: "Id",
            name: "Name",
            isDefault: "Is default",
          },
        },
      },
      admins: {
        title: "Admins",
        tab: "Admin | SignalOS",
        name: "Name",
        add: "Add admin",
        successAdded: "Admin added successfully",
        successRemoved: "Admin removed successfully",
        roleUpdateSuccess: "Admin role updated successfully",
        roleUpdateTooltip: "Only owner can update admin role",
        friendlyName: "Friendly name",
        role: "Role",
        areYouSureYouWantRemove: "Are You sure, you want to remove admin:",
        inviteAdminTooltip: "Only owner or admin can invite another admin",
        removeTooltipTitle: "Only organization owner can remove users.",
        removeAdminTooltip: "Only owner can remove another admin",
        roles: {
          owner: "Owner",
          admin: "Admin",
          supervisor: "Supervisor",
        },
      },
      operations: {
        title: "Event history",
        tab: "Event history | SignalOS",
        table: {
          code: "Code",
          state: "State",
          attemptsMade: "Attempts made",
          errorMessage: "Error message",
          message: "Message",
          willBeCanceled: "Will be canceled",
          author: "Operation author",
          USER: "User",
          SYSTEM: "System",
        },
        areYouSureYouWantToStop: "Are You sure You want to stop operation:",
        thisCantBeUndone: "This can't be undone.",
        stopThatOperation: "Stop that operation",
      },
      sites: {
        title: "Sites",
        tab: "Sites | SignalOS",
        name: "Name",
      },
      site: {
        setCheckedAsDefaultProfiles: "Set checked as default profiles",
        profiles: {
          profileId: "Profile id",
          name: "Name",
          accessControlId: "Access control id",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isDefault: "Is default",
        },
        badges: {
          type: "Type",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isInUse: "Is in use",
        },
        friendlyName: {
          gates: "GATES",
          building: "BUILDING",
          elevators: "ELEVATORS",
          parking: "PARKING",
        },
        profilesUpdateSuccess: "Profiles successfully updated",
      },
      badges: {
        title: "Badges",
        tab: "Badges | SignalOS ",
        table: {
          columns: {
            attachedBy: "Attached by",
            attachedTo: "Attached to",
            isInUse: "Is in use",
            cardNumber: "Card number",
          },
        },
      },
      groups: {
        title: "Groups",
        tab: "Groups | SignalOS",
        deleteGroupSuccess: "Group Deleted Successfully",
        deleteDialogMessage: "Are you sure you want to delete: ",
        removeFromDefaultSuccess: "Group removed from default successfully",
        setAsDefaultSuccess: "Group set as default successfully",
        setAsDefaultDialogMessage: "Are you sure you want to set ",
        setAsDefaultDialogMessage2: " as default?",
        removeFromDefaultDialogMessage: "Are you sure you want to remove ",
        removeFromDefaultDialogMessage2: " from default?",
        defaultAlreadyExists: "Default user group already exists",
        delete: "Delete",
        removeFromDefault: "Remove from default",
        setAsDefault: "Set as default",
        addGroupButton: "Add group",
        addGroupSuccess: "Group added successfully",
        toggleDefaultSupervisorRoleTooltip: "Only owner or admin can change default group",
        deleteGroupTooltip: "Only owner can delete a group",
        createGroupSupervisorRoleTooltip: "Only owner or admin can create a new group",
        addGroupDialog: {
          title: "Add new group",
          required: "Group name is required",
          groupName: "Group name",
        },
        table: {
          id: "Id",
          profiles: "Profiles",
          name: "Name",
          organization: "Organization",
          isDefault: "Is default",
        },
        profiles: "{{amount}} Profiles assigned",
      },
      group: {
        tabs: {
          carriers: "Users",
          profiles: "Profiles categories",
          profilesList: "Profiles",
        },
        carriers: {
          title: "Users",
          addCarrier: "Add users",
          delete: "Remove",
          deleteDialogMessage: "Are you sure you want to remove ",
          deleteDialogMessage2: " from group?",
          deleteSuccess: "User deleted from group successfully",
          addManySuccess: "Users added to group successfully",
          addOneSuccess: "User added to group successfully",
          removeCarrierTooltip: "Only owner or admin can remove a user",
          addCarrierDialog: {
            title: "Add users to group",
            email: "Email",
            alreadyAssignedTo: "User already assigned to: ",
            selectAll: "Select all users",
          },
          table: {
            email: "Email",
            createdAt: "Created At",
            updatedAt: "Updated At",
          },
        },
        profiles: {
          title: "Profiles",
          addProfile: "Add profiles",
          delete: "Delete",
          removeDialogMessage: "Are you sure you want to remove profile ",
          removeDialogMessage2: " from group?",
          removeSuccess: "Profile deleted from group successfully",
          addManySuccess: "Profiles added to group successfully",
          addOneSuccess: "Profile added to group successfully",
          categoriesAttachSuccess: "Profiles attach successfully",
          categoriesDetachSuccess: "Profiles detach successfully",
          updateFriendlyAccessLevelsSupervisorRoleTooltip:
            "Only owner or admin can manage group profiles",
          addProfileDialog: {
            title: "Add profiles to group",
            name: "Name",
            defaultProfile: "Profile is set as default",
            alreadyAssigned: "Profile is already assigned to group",
          },
          success: "Profiles updated successfully",
          table: {
            name: "Name",
            isDefault: "Is Default",
            createdAt: "Created At",
            updatedAt: "Updated At",
            type: "Type",
            accessName: "Access Name",
          },
        },
      },
      categories: {
        title: "Categories",
      },
      entrances: {
        title: "Entrances",
      },
      accessRights: {
        title: "Default access rights",
        tab: "Default access rights | SignalOS",
        defaultProfilesSuccess: "Profiles successfully updated",
      },
      floors: {
        title: "Floors",
      },
      elevators: {
        title: "Elevators",
      },
    },
  },
  pl: {
    translation: {
      common: {
        search: "Szukaj",
        all: "Wszystkie",
        bulkActions: "Wybierz",
        noResultsFound: "Nie znaleziono wyników",
        page: "Strona",
        of: "z",
        success: "Sukces",
        error: "Błąd",
        noData: "Brak danych",
        alreadyInUse: "Aktualnie w użyciu",
        assign: "Przypisz",
        yes: "Tak",
        no: "Nie",
        areYouSure: "Czy na pewno?",
        logout: "Wyloguj",
        aboutUs: "O nas",
        show: "Pokaż",
        default: "Domyślne",
        notDefault: "Nie domyślne",
        perPage: "Na stronę:",
        cancel: "Anuluj",
        confirm: "Zatwierdź",
        delete: "Usuń",
        availableSoon: "Dostępne wkrótce",
        noObjectsHere: "Nie ma tu jeszcze żadnych obiektów.",
        inherited: "Profil jest dziedziczony z grupy",
        privacyPolicy: "Polityka prywatności",
        termsAndConditions: "Regulamin",
        required: "Pole wymagane",
        button: {
          save: "Zapisz",
          cancel: "Anuluj",
          detach: "Odpisz",
        },
        profileVariants: {
          assigning: "Przypisywanie",
          removing: "Usuwanie",
          synchronizing: "Synchronizacja",
          scheduled: "Zaplanowany",
          inserting_connection_error: "Błąd podczas wstawiania połączenia",
          widthdrawing_connection_error: "Błąd połączenia",
          removing_error: "Błąd przy usuwaniu",
          assigning_error: "Błąd przy przypisywaniu",
          failed: "Błąd",
          max_limit_exhausted: "Maksymalna ilość prób osiągnięta",
          assigned: "Przypisany",
          queued_to_remove: "Zakolejkowany do usunięcia",
          queued_to_assign: "Zakolejkowany do przypisania",
          default: "N/A",
        },
        badgesVariants: {
          assigned: "Przypisany",
          available: "Dostępny",
          blocked: "Zablokowany",
          error: "Błąd",
          other: "Inny",
          max_limit_exhausted: "Maksymalna ilość prób osiągnięta",
          new: "Nowy",
          queued: "Zakolejkowany",
          inserting_connection_error: "Błąd przy wczytywaniu",
          widthdrawing_connection_error: "Błąd przy połączeniu",
        },
        status: {
          failed: "Nieudana",
          completed: "Zakończona",
          cancelled: "Anulowana",
        },
      },
      carriers: {
        title: "Użytkownicy",
        tab: "Użytkownicy | SignalOS",
        table: {
          columns: {
            sites: "Budynki",
            createdAt: "Utworzony",
            updatedAt: "Zaktualizowany",
          },
          buttons: {
            assignProfiles: "Przypisz profile",
            detachProfiles: "Odczep profile",
            youNeedTo: "Musisz najpierw wybrać budynek",
            delete: "Usuń",
          },
        },
        modal: {
          assignProfiles: "Przypisz profile do wybranych użytkowników",
          detachProfiles: "Odłącz profile od wybranych użytkowników",
          deleteMessage: "Czy na pewno chcesz usunąć użytkownika?",
        },
        assignSuccess: "Identyfikator przypisany pomyślnie",
        deleteSuccess: "Użytkownik usunięty pomyślnie",
        removeCarrierTooltip: "Tylko właściciel lub administrator może usunąć użytkownika",
      },
      carrier: {
        assignedSuccess: "Przypisano - wyniki zobaczysz po kilku minutach",
        detachedSuccess: "Odczepiono - wyniki zobaczysz po kilku minutach",
        accessControls: "Kontrole dostępu",
        assignBadge: "Przypisz identyfikator",
        assignProfilesToCarrier: "Przypisz profile",
        detachProfilesFromCarrier: "Odpisz profile",
        categoriesSuccess: "Profile zaktualizowane pomyślnie",
        categoriesAttachSuccess: "Profile przypisane pomyślnie",
        categoriesDetachSuccess: "Profile odpisane pomyślnie",
        accessControlsProfiles: {
          title: "Profile kontroli dostępu",
          columns: {
            accessControlId: "Id kontroli dostępu",
            state: "Status",
            createdAt: "Stworzone",
            updatedAt: "Uaktualnione",
          },
          completed: "Zakończony",
        },
        profiles: {
          title: "Kategorie Profili dostępu",
          assignProfiles: "Dodaj dostępy",
          detachCheckedProfiles: "Usuń dostępy",
          profileName: "Nazwa dostępu",
          thereAreNoProfilesToAssign: "Brak dostępów do przypisania",
          searchDialogTitle: "Wyszukaj użytkowników po dostępach",
          profilesFilterTitle: "Profile",
          columns: {
            profileId: "Id dostępu",
            name: "Nazwa",
            state: "Status",
            from: "Od",
            to: "Do",
            source: "Źródło",
            createdAt: "Stworzone",
            updatedAt: "Uaktualnione",
          },
          sorry: "Przykro nam, ale ten użytkownik, nie ma konta w tym budynku",
          admin: "Administrator",
          group: "Grupa",
          GROUP: "Grupa",
          INDIVIDUAL: "Indywidualnie",
        },
        friendlyProfiles: {
          title: "Profile dostepu",
        },
        badges: {
          title: "Identyfikator",
          assignBadge: "Przypisz identyfikator",
          thereAreNoBadges: "Brak identyfikatorów do przypięcia",
          errorTooltip:
            "Ten identyfikator pochodzi z zewnętrznego źródła i nie może być odczepiony",
          columns: {
            type: "Typ",
            accessControls: "Kontrola dostępu",
            createdAt: "Utworzona",
            updatedAt: "Uaktualniona",
          },
        },
        groups: {
          title: "Grupy",
          columns: {
            id: "Identyfikator",
            name: "Nazwa",
            isDefault: "Jest domyślna",
          },
        },
      },
      admins: {
        title: "Administratorzy",
        tab: "Administratorzy | SignalOS",
        name: "Nazwa",
        role: "Rola",
        add: "Dodaj administratora",
        successAdded: "Administrator dodany poprawnie",
        successRemoved: "Administrator pomyślnie usunięty",
        roleUpdateSuccess: "Rola administratora zaktualizowana pomyślnie",
        roleUpdateTooltip: "Tylko właściciel może zmienić rolę administratora",
        friendlyName: "Przyjazna nazwa",
        areYouSureYouWantRemove: "Czy jesteś pewny, że chcesz usunąć administratora:",
        inviteAdminTooltip: "Tylko właściciel lub administrator moze zapraszać administratorów",
        removeTooltipTitle: "Tylko właściciel organizacji może usuwać użytkowników",
        removeAdminTooltip: "Tylko właściciel może usunąć innego administratora",
        roles: {
          owner: "Właściciel",
          admin: "Administrator",
          supervisor: "Przeglądający",
        },
      },
      operations: {
        title: "Historia operacji",
        tab: "Historia operacji | SignalOS",
        table: {
          code: "Kod",
          state: "Status",
          attemptsMade: "Ilość prób",
          errorMessage: "Wiadomość z błędem",
          message: "Wiadomość",
          willBeCanceled: "Będzie anulowany",
          author: "Autor operacji",
          USER: "Użytkownik",
          SYSTEM: "System",
        },
        areYouSureYouWantToStop: "Czy na pewno chcesz przerwać operacje:",
        thisCantBeUndone: "Nie można przerwać anulowania.",
        stopThatOperation: "Przerwij tą operację",
      },
      sites: {
        title: "Budynki",
        tab: "Budynki | SignalOS",
        name: "Nazwa",
      },
      site: {
        setCheckedAsDefaultProfiles: "Ustaw zaznaczone jako profile domyślne",
        profiles: {
          profileId: "Id profilu",
          name: "Nazwa",
          accessControlId: "Id kontroli dostępu",
          createdAt: "Utworzony",
          updatedAt: "Zaktualizowany",
          isDefault: "Jest domyślny",
        },
        badges: {
          type: "Typ",
          createdAt: "Utworzona",
          updatedAt: "Zaktualizowana",
          isInUse: "Jest w użyciu",
        },
        friendlyName: {
          gates: "BRAMKI",
          building: "BUDYNEK",
          elevators: "WINDY",
          parking: "PARKING",
        },
        profilesUpdateSuccess: "Profile pomyślnie zaktualizowane",
      },
      badges: {
        title: "Identyfikatory",
        tab: "Identyfikatory | SignalOS ",
        table: {
          columns: {
            attachedBy: "Przypisany przez",
            attachedTo: "Przypisany do",
            isInUse: "Jest w użyciu",
            cardNumber: "Numer karty",
          },
        },
      },
      groups: {
        title: "Grupy",
        tab: "Grupy | SignalOS",
        deleteGroupSuccess: "Grupa usunięta pomyślnie",
        deleteDialogMessage: "Czy na pewno chcesz usunąć: ",
        removeFromDefaultSuccess: "Grupa domyślna usunięta pomyślnie",
        setAsDefaultSuccess: "Grupa ustawiona jako domyślna pomyślnie",
        setAsDefaultDialogMessage: "Czy na pewno chcesz ustawić ",
        setAsDefaultDialogMessage2: " jako grupę domyślną?",
        removeFromDefaultDialogMessage: "Czy na pewno chcesz usunąć ",
        removeFromDefaultDialogMessage2: " z grupy domyślnej?",
        defaultAlreadyExists: "Grupa domyślna już istnieje",
        delete: "Usuń",
        removeFromDefault: "Usuń z domyślnej",
        setAsDefault: "Ustaw jako domyślną",
        addGroupButton: "Dodaj grupę",
        addGroupSuccess: "Grupa pomyślnie utworzona",
        toggleDefaultSupervisorRoleTooltip:
          "Tylko właściciel lub administrator może zmienić grupę domyślną",
        deleteGroupTooltip: "Tylko właściciel może usunąć grupę",
        createGroupSupervisorRoleTooltip:
          "Tylko właściciel lub administrator może utworzyć nową grupę",
        addGroupDialog: {
          title: "Dodaj nową grupę",
          required: "Nazwa grupy jest wymagana",
          groupName: "Nazwa grupy",
        },
        table: {
          id: "Identyfikator",
          name: "Nazwa",
          organization: "Organizacja",
          profiles: "Profile",
          isDefault: "Jest domyślna",
        },
        profiles: "{{amount}} Przypisanych profili",
      },
      group: {
        tabs: {
          carriers: "Użytkownicy",
          profiles: "Kategorie profili dostępu",
          profilesList: "Profile dostępu",
        },
        carriers: {
          title: "Użytkownicy",
          addCarrier: "Dodaj użytkownika",
          delete: "Usuń",
          deleteDialogMessage: "Czy na pewno chcesz usunąć  ",
          deleteDialogMessage2: " z grupy?",
          deleteSuccess: "Użytkownik pomyślnie usunięty z grupy",
          addManySuccess: "Użytkownicy pomyślnie dodani do grupy",
          addOneSuccess: "Użytkownik pomyślnie dodany do grupy",
          removeCarrierTooltip: "Tylko właściciel lub administrator może usunąć użytkownika",
          addCarrierDialog: {
            title: "Dodaj użytkowników do grupy",
            email: "Email",
            alreadyAssignedTo: "Użytkownik juz przypisany do grupy: ",
            selectAll: "Zaznacz wszystkich użytkowników",
          },
          table: {
            email: "Email",
            createdAt: "Utworzony",
            updatedAt: "Zaktualizowany",
          },
        },
        profiles: {
          title: "Profile dostępu",
          addProfile: "Dodaj profil dostępu",
          delete: "Usuń",
          removeDialogMessage: "Czy na pewno chcesz usunąć profil ",
          removeDialogMessage2: " z grupy?",
          removeSuccess: "Profil dostępu usunięty z grupy pomyślnie",
          addManySuccess: "Profile dostępu dodane do grupy pomyślnie",
          addOneSuccess: "Profil dostępu dodany do grupy pomyślnie",
          categoriesAttachSuccess: "Profile przypisane pomyślnie",
          categoriesDetachSuccess: "Profile odpisane pomyślnie",
          updateFriendlyAccessLevelsSupervisorRoleTooltip:
            "Tylko właściciel lub administrator może zarządzać profilami w grupie",
          addProfileDialog: {
            title: "Dodaj profile dostępu do grupy",
            name: "Nazwa",
            defaultProfile: "Profil dostępu jest ustawiony jako domyślny",
            alreadyAssigned: "Profil dostępu jest już przypisany do grupy",
          },
          success: "Profile zaktualizowane pomyślnie",

          table: {
            name: "Nazwa",
            isDefault: "Jest domyślny",
            createdAt: "Utworzony",
            updatedAt: "Zaktualizowany",
            type: "Typ",
            accessName: "Nazwa dostępu",
          },
        },
      },
      entrances: {
        title: "Przejścia",
      },
      accessRights: {
        title: "Domyślne dostępy",
        tab: "Domyślne dostępy | SignalOS",
        defaultProfilesSuccess: "Profile pomyślnie zaktualizowane",
      },
      floors: {
        title: "Piętra",
      },
      elevators: {
        title: "Windy",
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
