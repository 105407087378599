import { useEffect, useState } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box, Button, CssBaseline, ThemeProvider, Typography } from "@material-ui/core";
import { initializeI18n } from "./i18n";
import { RTL } from "./components/rtl";

import { useSettings } from "./contexts/settings-context";

import routes from "./routes";
import { createCustomTheme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import store from "./store/ApplicationStore";
import Loader from "./components/atoms/Loader/Loader";
import { organizationApi } from "./api/organization";
import { useMounted } from "./hooks/use-mounted";
import { AbilityContextProvider } from "./contexts/ability-context";
import { Role } from "./types/organization/admins";

export const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isAuthenticated, user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();
  const mounted = useMounted();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [role, setRole] = useState<Role>(Role.SUPERVISOR);

  const getToken = async () => {
    try {
      setIsLoadingToken(true);
      const token = await getAccessTokenSilently();
      store.user.setToken(token);
      await getSites();
      await getMe();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      store.user.setUserOrganizationId(user["https://platform.signalos.io/active_org_id"]);
      getToken();
    }
  }, [isAuthenticated, user]);

  const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false);

  const getSites = async () => {
    try {
      setIsLoadingSites(true);
      if (mounted.current) {
        const sites = await organizationApi.getSites();

        store.site.setSites(sites);
        store.site.setSiteToken(sites[0].token);
        store.site.setPickedSite(sites[0]);
        setIsLoadingSites(false);
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setIsLoadingSites(false);
      }
    }
  };

  const getMe = async () => {
    try {
      if (mounted.current) {
        const me = await organizationApi.getMe();
        setRole(me?.role);
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setIsLoadingSites(false);
      }
    }
  };

  useEffect(() => {
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  const renderContent = () => {
    if (isLoading || isLoadingToken || isLoadingSites) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Loader />
        </Box>
      );
    }
    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography>Oops... {error.message}</Typography> <br />
          <Button variant="contained" onClick={() => logout()}>
            Logout
          </Button>
        </Box>
      );
    } else {
      return content;
    }
  };

  return (
    <AbilityContextProvider role={role ? role : Role.SUPERVISOR}>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          {renderContent()}
        </RTL>
      </ThemeProvider>
    </AbilityContextProvider>
  );
};
