export interface Admin {
  id: number;
  friendlyName: string;
  email: string;
  role: Role;
}

export enum Role {
  ADMIN = "admin",
  OWNER = "owner",
  SUPERVISOR = "supervisor",
}
