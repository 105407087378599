export const throttle = 150;

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const userInfo = {
  firstName: process.env.REACT_APP_USER_FIRST_NAME,
  lastName: process.env.REACT_APP_USER_LAST_NAME,
  email: process.env.REACT_APP_USER_EMAIL,
  organizations: process.env.REACT_APP_USER_ORGANIZATIONS,
  imgUrl: process.env.REACT_APP_USER_IMG_URL,
};

export const appInfo = {
  versionType: process.env.REACT_APP_VERSION_TYPE,
  version: process.env.REACT_APP_VERSION,
  fullName: process.env.REACT_APP_FULL_NAME,
  shortCut: process.env.REACT_APP_SHORTCUT_NAME,
  icon: process.env.REACT_APP_ICON,
};
