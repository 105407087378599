import { makeAutoObservable } from "mobx";
import { Site } from "src/types/organization/sites";

class SiteStore {
  constructor() {
    makeAutoObservable(this);
  }

  sites: Site[] = [];

  siteToken: string = null;

  pickedSite: Site = null;

  setSites = (sites: Site[]) => {
    this.sites = sites;
  };

  setSiteToken = (siteToken: string) => {
    this.siteToken = siteToken;
  };

  setPickedSite = (site: Site) => {
    this.pickedSite = site;
  };
}

export default SiteStore;
